import React from "react";

export const Card = (props) => {
  return (
    <>
      <section class="relative py-8 overflow-hidden">
        <div class=" mx-auto px-4">
          <div class={`${props.row} justify-between flex  bg-gradient-to-r from-black via-gray-900 to-gray-700 rounded-md  flex-wrap items-center`}>
            <div class="w-full lg:w-1/2 p-6">
              <div class="">
                <h2 class={`mb-6 ${props.h2} font-heading font-bold text-6xl text-white`}>
                  {props.title}
                </h2>
                <p class={`mb-12 ${props.h2} text-lg text-gray-200`}>
                  {props.desc}
                </p>
              </div>
            </div>
            <div class="w-full lg:w-1/2 p-6">
              <div class={`${props.image} bg-gradient-orange    rounded-3xl`}>
                <img
                  class="relative md:h-96 rounded  transform transition ease-in-out duration-500"
                  src={props.img}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
