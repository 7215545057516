import React from "react";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  return (
    <>
      <section
        id="services"
        style={{
          backgroundImage: `url("https://ik.imagekit.io/cc5n4mgg4x/bg-map_1GquUx3nN.jpg")`,
        }}
        className="bg-fixed"
      >
        <section className=" py-16">
          <div className="container mx-auto ">
            <div className="flex space-y-5 flex-col items-center">
              <h3 className="text-base lg:text-3xl text-gray-800 font-medium">
                Our Services
              </h3>
              <p className="text-center text-sm px-4 lg:w-3/4 text-gray-600 font-light">
                USD Services offers a variety of IT consulting and Digital
                Marketing services which are flexible, robust & reliable as per
                our client’s requirements. At Vcana, we believe in building
                brands rather than a just a company or a name. Following is the
                list of services that we provide to our clients.
              </p>
            </div>
            <div className="grid grid-cols-1  lg:grid-cols-3 py-16">
              {ServiceData.map((e, index) => {
                return (
                  <div
                    key={index}
                    className="flex border hover:shadow-md duration-500 cursor-pointer m-6 max-w-full flex-col space-y-4 p-10 bg-white shadow-2xl servicecard rounded-3xl items-center"
                  >
                    <div className="flex flex-col items-center justify-center h-full">
                      <img className="w-40 aspect-square object-cover" src={e.img} alt="" />
                      <h2 className="text-[20px] text-center font-bold mt-4">
                        {e.title}
                      </h2>
                      <p className="text-center font-sans text-gray-600 mt-4">
                        {e.desc}
                      </p>
                      <a
                        href={e.route}
                        className="bg-[#E50403] text-white px-5 text-sm serbtn p-2 rounded-3xl mt-4"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

const ServiceData = [
  {
    img: "https://www.q5infotech.com/wp-content/uploads/2020/09/Web-Development.jpeg",
    title: "Web Development ",
    route: "#contact",
    desc: "We create custom web solutions using cutting-edge technologies. From responsive websites to complex web applications, our expert developers deliver high-quality, scalable solutions tailored to your needs.",
  },
  {
    img: "https://norsecorp.com/wp-content/uploads/2020/02/fleet-scaled.jpg",
    title: "Fleet Management",
    route: "#contact",
    desc: "Our fleet management solutions help you optimize vehicle operations, track maintenance, monitor fuel usage, and improve driver safety. Get real-time insights and streamline your fleet operations with our comprehensive software platform.",
  },
  {
    img: "https://www.cloudways.com/blog/wp-content/uploads/WordPress-Website-Hacked.jpg",
    title: "Hack Site",
    route: "#contact",
    desc: "We offer comprehensive website security solutions to protect your online assets from hackers and cyber threats. Our services include website vulnerability assessments, penetration testing, and real-time monitoring to keep your site secure and operational.",
  },
  {
    img: "https://www.blogtyrant.com/wp-content/uploads/2012/03/choose-best-blog-hosting.png",
    title: "Host",
    route: "#contact",
    desc: "We provide reliable and scalable web hosting solutions to ensure your website is always accessible and secure. Our services include domain registration, SSL certificates, and scalable hosting options to support your growing business needs.",
  },
  {
    img: "https://aufaitux.com/wp-content/uploads/2020/05/UIUX-designing-1.jpg",
    title: "Ui/UX Design",
    route: "#contact",
    desc: "We specialize in creating intuitive and user-friendly designs that enhance user engagement and satisfaction. Our UX/UI design services include user research, wireframing, prototyping, and visual design to ensure your products are both functional and aesthetically pleasing.",
  },
  {
    img: "/greenUSD.jpg",
    title: "Green USD",
    route: "#contact",
    desc: "We offer comprehensive digital marketing solutions to help businesses grow and succeed online. Our services include SEO, PPC, social media marketing, email marketing, and content marketing to drive traffic, increase conversions, and boost brand awareness.",
  },
  {
    img: "https://techtradigital.com/wp-content/uploads/2020/04/2020-google-Ideas-to-Increase-Business-Sale-Through-Digital-Marketing.jpg",
    title: "Digital Marketing",
    route: "#contact",
    desc: "At a high level, digital marketing refers to advertising delivered through digital channels such as search engines, websites, social media",
  },
  {
    img: "/825159_preview.jpg",
    title: "Logo Design",
    route: "#contact",
    desc: "We specialize in creating visually appealing and impactful logos that represent your brand's identity. Our logo design services include brand strategy, concept development, and visual design to ensure your logo is both unique and representative of your brand.",
  },
];
// export const ServiceData = [
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Web_Development_Uyf_uu7W5n.png",
//     title: "Web Development",
//     route: "/service/web-development",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/App_Development_AgXFoln1kA.png",
//     title: "App Development",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Appplication_Development_orpbv4Y2e.png",
//     title: "Application Development",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Digital_Marketing_WNJgx3UpRP.png",
//     title: "Hacked site cleanup",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Ecommerce_Development_dRNUi2wLhK.png",
//     title: "Ecommerce Development",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Branding_5RZW84Lgnx.png",
//     title: "Branding",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
// ];

export default Services;
