import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const Testimonials = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url("https://ik.imagekit.io/nboj7z3sl/OurBestSerBanner.18e034c4dd73e4cf5b58_3HPPVvCXa.png")`,
        }}
        className="bg-fixed py-12"
      >
        <section class="relative  py-10  overflow-hidden">
          <div class="container mx-auto px-4">
            <h2 class="mb-5   font-bold text-xl py-8 md:text-3xl  text-black text-center">
              Testimonials
            </h2>
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              infinite={true}
              autoPlay={"desktop" ? true : false}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            >
              {Testi.map((e) => {
                return (
                  <div class=" p-2">
                    <div class="h-full p-0.5 bg-gradient-cyan transform hover:-translate-y-4 rounded-10 shadow-5xl transition ease-out duration-1000">
                      <div class="h-full border px-7 py-8 bg-white rounded-lg">
                        <p class="mb-8  text-gray-900">{e.review}</p>
                        <span className="flex space-x-4 items-center">
                          <img class="w-14 rounded-full" src={e.img} alt="" />
                          <span>
                            <h3 class="mb-1 font-heading font-bold text-lg text-gray-900">
                              {e.name}
                            </h3>
                            <p class="text-sm text-gray-600">{e.role}</p>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </section>
      </section>
    </>
  );
};

const Testi = [
  {
    img: "https://indiblogger.s3.amazonaws.com/avatars/200/ddfd23fc0e15f868bd10285cd064193c.jpg",
    name: "Deepak Rathor",
    role: "React JS Developer",
    review:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique tempore voluptatem hic ab facilis fugiat neque aperiam nobis, nesciunt atque.",
  },
  {
    img: "https://indiblogger.s3.amazonaws.com/avatars/200/ddfd23fc0e15f868bd10285cd064193c.jpg",
    name: "Deepak Rathor",
    role: "React JS Developer",
    review:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique tempore voluptatem hic ab facilis fugiat neque aperiam nobis, nesciunt atque.",
  },
  {
    img: "https://indiblogger.s3.amazonaws.com/avatars/200/ddfd23fc0e15f868bd10285cd064193c.jpg",
    name: "Deepak Rathor",
    role: "React JS Developer",
    review:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique tempore voluptatem hic ab facilis fugiat neque aperiam nobis, nesciunt atque.",
  },
];

export default Testimonials;
