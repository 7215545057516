import React from "react";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <section className="bg-[#232323] md:py-16 my-auto items-center flex">
        <div className="container md:flex p-3 md:px-6 lg:space-y-0 space-y-10 mx-auto justify-between  ">
          <div className="lg:w-2/4 m-2 lg:m-0 pt-5 space-y-5 lg:space-y-10">
            <h2 className="text-white tracking-wide font-semibold lg:text-4xl text-3xl leading-tight">
              Best Web Development & Digital Marketing Company
            </h2>
            <p className="text-white lg:text-lg tracking-wide ">
              We have 7+ years of experience in IT Industry. Company delivered
              Best Web Design and Digital Marketing Services to the different
              purposes in All Industries.
            </p>
            <button onClick={()=>navigate('/service')} className="bg-[#31AAB7] py-2 px-6 text-white text-lg font-medium rounded-md">
              Check Services
            </button>
          </div>
          <div className="lg:w-2/4  justify-center flex lg:justify-end">
            <div className="lg:w-4/6 w-full m-2 lg:m-0 rounded-xl  bg-white">
              <h2 className="bg-[#31AAB7] text-white rounded-t-xl py-4 text-center text-2xl font-semibold">
                Consult with Our Expert
              </h2>
              <span className="flex flex-col p-5 space-y-5">
                <input
                  className="border border-[#dbdbdb] py-3 px-3 rounded-md"
                  placeholder="Your Name"
                  type="text"
                  name=""
                  id=""
                />
                <input
                  className="border border-[#dbdbdb] py-3 px-3 rounded-md"
                  placeholder="Your Phone"
                  type="tel"
                  name=""
                  id=""
                />
                <select
                  className="border border-[#dbdbdb] py-3 px-3 rounded-md"
                  name=""
                  id=""
                >
                  <option value="">What Service You Want</option>
                  <option value="Security Analysis">Security Analysis</option>
                  <option value="Security Analysis">
                    3d Designing And Printing
                  </option>
                  <option value="Security Analysis">It Training</option>

                  <option value="Security Analysis">Digital Marketing</option>
                  <option value="Security Analysis">Web Development</option>
                  <option value="Security Analysis">VPS</option>
                </select>
                <button className="border py-2 px-3 bg-[#232323]  text-white text-lg font-medium rounded-md">
                  Submit
                </button>
              </span>
            </div>
          </div>
        </div>
      </section> */}

      <div className="relative border-b flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
        <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src="https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
            alt=""
          />
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-gray-200">
              Brand new
            </p>
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Everything you
              <br className="hidden md:block" />
              can imagine{" "}
              <span className="inline-block text-deep-purple-accent-400">
                is real
              </span>
            </h2>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
              Transform your digital presence with our cutting-edge solutions. From web development
              to digital marketing, we deliver excellence that helps your business thrive in the
              modern world.
            </p>
            <div className="flex items-center">
              <a
                href="/#contact"
                className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#E50403] hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              >
                Get started
              </a>
              <a
                href="/#about"
                aria-label=""
                className="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
