function Terms() {
    return (
        <>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>

                <div className="space-y-6">
                    <section>
                        <h2 className="text-xl font-semibold mb-3">1. Acceptance of Terms</h2>
                        <p className="text-gray-700">
                            By accessing and using USD Services' website and services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use our services.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">2. Services</h2>
                        <p className="text-gray-700">
                            USD Services provides web development, app development, SEO, and SMM services. We reserve the right to modify, suspend or discontinue any part of our services at any time without notice.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">3. Client Responsibilities</h2>
                        <ul className="list-disc ml-6 mt-2 text-gray-700">
                            <li>Provide accurate and complete information for project requirements</li>
                            <li>Review and provide feedback in a timely manner</li>
                            <li>Make payments according to agreed terms</li>
                            <li>Maintain confidentiality of project details</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">4. Intellectual Property</h2>
                        <p className="text-gray-700">
                            All intellectual property rights related to our services remain the property of USD Services until full payment is received. Upon payment, specified deliverables will be transferred to the client.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">5. Payment Terms</h2>
                        <p className="text-gray-700">
                            Payment terms will be specified in individual project agreements. Late payments may result in service suspension and additional fees.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">6. Limitation of Liability</h2>
                        <p className="text-gray-700">
                            USD Services shall not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of our services.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">7. Contact Information</h2>
                        <p className="text-gray-700">
                            For any questions regarding these terms, please contact us at helpdesk@usdservices.in or call us at +91-7588223343.
                        </p>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Terms;