import axios from "axios";
import React, { useEffect, useState } from "react";
import { Baseurl } from "../../Baseurl";
import Blog, { Bloglist, BlogProp } from "../Blog/Blog";
import ContactUs from "../ContactUs/ContactUs";
import Services from "../Services/Services";
import Aboutus from "./Aboutus";
import Hero from "./Hero";
import OurPortFolio from "./Portfolio/OurPortFolio";
import PricingTable from "./PricingTable";
import Stat from "./Stat";
import Team from "./Team";
import Testimonials from "./Testimonials";
import WhyChooseUs from "./WhyChooseUs";

const Home = () => {
  const [blogData, setBlogData] = useState([]);
  const [loader, setLoader] = useState(false);

  const getBlog = async () => {
    const url = `${Baseurl}web/get-blogs`;
    try {
      const res = await axios.get(url);
      setBlogData(res.data.data);
      setLoader(true);
    } catch (error) { }
  };
  useEffect(() => {
    getBlog();
  }, []);
  return (
    <>
      <Hero />
      <Stat />
      <Services />
      <Aboutus />
      <WhyChooseUs />
      <ContactUs />
    </>
  );
};

export default Home;
