import React from "react";
import PortFolioCard from "./PortFolioCard";

const OurPortFolio = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url("https://ik.imagekit.io/cc5n4mgg4x/bg-map_1GquUx3nN.jpg")`,
        }}
        className="bg-fixed py-12"
      >
        <section className="py-16">
          <div className="container px-8 mx-auto">
            <div className="flex pb-12 flex-col space-y-5 items-center">
              <h2 className="text-xl lg:text-2xl text-gray-700 font-semibold">
                Our PortFolio
              </h2>
              <h3 className="text-2xl lg:text-4xl  font-bold text-center">
                We Work to innovate & are proud
                <br />
                of what we've created
              </h3>
              <p className=" text-center text-sm lg:text-base">
                YOU'LL LOVE TO SEE SOME OF OUR BEST PROJECTS. TAKE A LOOK
              </p>
            </div>
            <PortFolioCard />
          </div>
        </section>
      </section>
    </>
  );
};

export default OurPortFolio;
