import { MdCall, MdEmail } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";

const Address = [
  {
    icon: <FiMapPin />,
    title: "Our Head Office",
    address: "USD Services, Hadapsar, Pune, Maharashtra, India- 412308",
    type: "address",
  },
  {
    icon: <MdCall />,
    title: "Call for Help",
    address: "+91-7588223343",
    type: "phone",
  },
  {
    icon: <MdEmail />,
    title: "Email for Information",
    address: "helpdesk@usdservices.in",
    type: "email",
  },
];

export default Address;
