import React from "react";

const Aboutus = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url("https://ik.imagekit.io/nboj7z3sl/OurBestSerBanner.18e034c4dd73e4cf5b58_3HPPVvCXa.png")`,
        }}
        className=""
      >
        <section id="about" class="pt-20 lg:pt-[120px] pb-12 lg:pb-[90px] overflow-hidden">
          <div class="container px-4 mx-auto">
            <div class="flex flex-wrap justify-between items-center">
              <div class="w-full lg:w-6/12 px-4">
                <div class="flex items-center -mx-3 sm:-mx-4">
                  <div class="w-full xl:w-1/2 px-3 sm:px-4">
                    <div class="py-3 sm:py-4">
                      <img
                        src="https://cdn.tailgrids.com/1.0/assets/images/services/image-1.jpg"
                        alt=""
                        class="rounded-2xl w-full"
                      />
                    </div>
                    <div class="py-3 sm:py-4">
                      <img
                        src="https://cdn.tailgrids.com/1.0/assets/images/services/image-2.jpg"
                        alt=""
                        class="rounded-2xl w-full"
                      />
                    </div>
                  </div>
                  <div class="w-full xl:w-1/2 px-3 sm:px-4">
                    <div class="my-4 relative z-10">
                      <img
                        src="https://cdn.tailgrids.com/1.0/assets/images/services/image-3.jpg"
                        alt=""
                        class="rounded-2xl w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 xl:w-5/12 px-4">
                <div class="mt-10 lg:mt-0">
                  <span class="font-semibold text-lg text-primary mb-2 block">
                    About us
                  </span>
                  <h2 class="font-bold text-3xl sm:text-4xl text-black mb-8">
                    Make your customers happy by giving services.
                  </h2>
                  <p class="text-base text-body-color mb-8">
                    Welcome to USD Services, your trusted partner in digital innovation. We specialize in delivering cutting-edge web development and digital marketing solutions that help businesses thrive in today's competitive landscape. Our team of experts combines technical excellence with creative vision to transform your ideas into powerful digital experiences.
                  </p>
                  <p class="text-base text-body-color mb-12">
                    From custom web applications and responsive designs to comprehensive digital marketing strategies, we offer end-to-end solutions tailored to your unique business needs. Our client-centric approach, technical expertise, and commitment to excellence ensure that we deliver results that exceed expectations and drive real business growth.
                  </p>
                  <a
                    class="
                  py-4
                  px-10
                  lg:px-8
                  xl:px-10
                  inline-flex
                  items-center
                  justify-center
                  text-center text-white text-base
                  bg-[#E50403]
                  hover:bg-opacity-90
                  font-normal
                  rounded-lg
                  "
                    href="#contact"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Aboutus;
