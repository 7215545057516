import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../Home/Common";

const HackedSiteClean = () => {
  const navigate = useNavigate();
  return (
    <>
      <section class="relative py-8 overflow-hidden">
        <div class=" mx-auto px-4">
          <div class="flex  bg-white rounded-md  flex-wrap items-center">
            <div class="w-full lg:w-1/2 p-6">
              <div class="lg:max-w-lg">
                <h2 class="mb-6 font-heading font-bold text-5xl text-gray-900">
                  Hacked Site Cleanup Service in India
                </h2>
                <p class="mb-12 text-lg text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Porro quisquam enim necessitatibus veritatis eos impedit ipsa
                  libero maxime asperiores, delectus illo adipisci harum tempore
                  aspernatur.
                </p>
                <button
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                  className="bg-[#E50403] text-white p-2 px-5 rounded-md"
                >
                  Contact us
                </button>
              </div>
            </div>
            <div class="w-full lg:w-1/2 p-6">
              <div class="bg-gradient-orange flex justify-center md:justify-end  rounded-3xl">
                <img
                  class="relative md:h-96 rounded  transform transition ease-in-out duration-500"
                  src="https://www.collectiveray.com/images/wordpress/wordpress-security/Wordpress_Security_-_Prevent_your_WordPress_Website_from_Getting_Hacked.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-center pt-16 pb-6 text-black text-5xl font-semibold">
          Bring your business online
        </h2>
        <p className="text-center text-gray-400">
          Regardless of the mode of the intrusion, you'll want to clean up after
          a hack to ensure the integrity of your site
          <br /> and to ensure that it cannot be compromised again.
        </p>
      </section>
      <Card
        title="How to know?"
        desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        img="https://miro.medium.com/max/1400/1*0Y_ty19ogefmWDRuZCdJ9A.jpeg"
        image="flex justify-end"
        row="flex-row"
        h2="text-left"
      />
      <Card
        title="What to do?"
        desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        img="https://bc-malesurvivors.com/wp-content/uploads/2014/01/todotalks.jpg"
        row="flex-row-reverse"
        h2="text-right"
        image="flex justify-start"
      />
      <Card
        title="Reasons"
        desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        img="https://thumbs.dreamstime.com/b/reasons-complex-like-puzzle-pictured-as-word-reasons-puzzle-pieces-to-show-reasons-can-be-difficult-needs-164219526.jpg"
        image="flex justify-end"
        row="flex-row"
        h2="text-left"
      />
      <Card
        title="Causes"
        desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        img="https://www.pctechnologies.net/images/easyblog_shared/June_2017/6-9-17/r_c_analysis_400.jpg"
        row="flex-row-reverse"
        h2="text-right"
        image="flex justify-start"
      />
      <Card
        title="What we do"
        desc="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters."
        img="https://importyourcar.ng/wp-content/uploads/2018/03/what-we-do.jpg"
        image="flex justify-end"
        row="flex-row"
        h2="text-left"
      />
    </>
  );
};

export default HackedSiteClean;
