import React from "react";
import { useLocation } from "react-router-dom";

const BlogRead = () => {
  const location = useLocation();
  const data = location.state;
  return (
    <>
      <section className="py-6 bg-[#7e7d7d4f] space-y-4 md:mx-0">
        <div className="container md:w-3/4 p-4 w-full  bg-white   border rounded-md shadow-lg mx-auto">
          <span className="space-y-2 flex flex-col">
            <h1 className="text-2xl font-semibold">{data.name}</h1>
            <h3 className="text-xs pb-2 text-black">
              Published Date : 
              {`${new Date(data.createdAt).getDate()}/${
                new Date(data.createdAt).getMonth() + 1
              }/${new Date(data.createdAt).getFullYear()}`}
               &nbsp; by <span className="text-black"> USDService</span>
            </h3>
            <span className="py-4 border-t border-[#ad9f9f54]">
              <p className="text-sm">{data.long_desc}</p>
            </span>
          </span>
        </div>
      </section>
    </>
  );
};

export default BlogRead;
