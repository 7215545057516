import React from "react";

const Client = () => {
  return (
    <>
      <section class="text-black bg-red-200 body-font">
        <div class="container px-5 py-20 mx-auto">
          <div class="flex flex-col text-center w-full mb-8">
            <h1 class="sm:text-5xl text-3xl font-semibold title-font mb-4 ">
              Our Client
            </h1>
          </div>
          <div class="flex flex-wrap -m-4">
            {ClientData.map((e) => {
              return (
                <div class="lg:w-1/3 sm:w-1/2 p-4">
                  <div class="flex bg-white rounded-2xl relative">
                    <img
                      alt="gallery"
                      class=" w-full rounded-2xl border p-2 h-full object-cover object-center"
                      src={e.img}
                    />
                    <div class="px-8 py-10 absolute  w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 duration-700 ease-in-out">
                      <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                        {e.cat}
                      </h2>
                      <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                        {e.title}
                      </h1>
                      <p class="leading-relaxed text-gray-600">{e.desc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

const ClientData = [
  {
    img: "https://ik.imagekit.io/nboj7z3sl/gobeens_7yL3m0wLx.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660506601303",
    title: "Gobeens Technology IT Solutions",
    desc: "Web Development & Digital Marekting Agency",
    cat : "Website"
  },
  {
    img: "https://ik.imagekit.io/nboj7z3sl/PDC_AmMwmXqf1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660506601296",
    title: "PDC Healthcare",
    desc: "PDC Healthcare: Patient Identification & Safety Supplies",
    cat : "Website"
  },
  {
    img: "https://ik.imagekit.io/nboj7z3sl/PDC_AmMwmXqf1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660506601296",
    title: "PDC Healthcare",
    desc: "PDC Healthcare: Patient Identification & Safety Supplies",
    cat : "Website"
  },
  {
    img: "https://ik.imagekit.io/nboj7z3sl/mbbsvidesh_fXUd_RfJg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660506601292",
    title: "Best MBBS Abroad Consultants",
    desc: "Best MBBS Abroad Consultants in Gwalior | MBBSVidesh",
    cat : "Website"
  },
  {
    img: "https://ik.imagekit.io/nboj7z3sl/gobeens_7yL3m0wLx.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660506601303",
    title: "Gobeens Technology IT Solutions",
    desc: "Web Development & Digital Marekting Agency",
    cat : "Website"
  },
  {
    img: "https://ik.imagekit.io/nboj7z3sl/PDC_AmMwmXqf1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660506601296",
    title: "PDC Healthcare",
    desc: "PDC Healthcare: Patient Identification & Safety Supplies",
    cat : "Website"
  },
  {
    img: "https://ik.imagekit.io/nboj7z3sl/mbbsvidesh_fXUd_RfJg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660506601292",
    title: "Best MBBS Abroad Consultants",
    desc: "Best MBBS Abroad Consultants in Gwalior | MBBSVidesh",
    cat : "Website"
  },
];

export default Client;
