import React, { useEffect, useState } from "react";
import { FormStyle } from "../ContactUs/Form";
import axios from "axios";
import { Baseurl } from "../../Baseurl";
import { Loader } from "../../Layout/Loader";

const Career = () => {
  const [loader, setLoader] = useState(false);
  const [post, setPost] = useState(false);
  const [job, setJob] = useState([]);

  const [pos, setPos] = useState();

  const [postApp, setPostApp] = useState({
    name: "",
    email: "",
    phone: "",
    job: "",
  });

  const PostApplication = async (e) => {
    e.preventDefault();
    const url = `${Baseurl}web/add-application`;
    try {
      const res = await axios.post(url, postApp);
      if (res.data.message === `added details for ${res.data.data._id}`) {
        alert("Application Sent Successfully");
        setPostApp({
          name: "",
          email: "",
          phone: "",
          job: "",
        });
      } else {
      }
    } catch (error) {
      alert("Something Went Wrong");
    }
  };

  const getCareer = async () => {
    const url = `${Baseurl}web/get-jobs`;
    try {
      const res = await axios.get(url);
      setJob(res.data.data);
      setLoader(true);
    } catch (error) {}
  };
  useEffect(() => {
    getCareer();
  }, []);
  return (
    <>
      <section class="pt-14 bg-red-100 pb-14 overflow-hidden">
        <div class="container mx-auto px-4">
          <h2 class="mb-20 font-heading font-bold text-3xl sm:text-5xl text-gray-900">
            Available positions
          </h2>
          {loader ? (
            <div class="grid grid-cols-1 gap-4 md:grid-cols-3 -m-3 mb-12">
              {job.map((e) => {
                return (
                  <div class="w-full shadow-xl rounded-xl bg-white p-4 border border-gray-300">
                    <a
                      class="block h-full border-gray-200 hover:border-gray-300 rounded-md"
                      href="#"
                    >
                      <p class="mb-7 font-heading font-semibold text-xs text-gray-500 uppercase tracking-px">
                        {e.location}
                      </p>
                      <h3 class="mb-1.5 font-heading font-semibold text-lg text-gray-900">
                        {e.name}
                      </h3>
                      <span className="flex items-center justify-between">
                        <p class="text-gray-600 text-sm">
                          {e.type} | {e.experience} Yrs
                        </p>
                        <h3
                          onClick={() => setPos(e)}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalCenter"
                          className="text-white text-sm py-1 px-3 rounded bg-[#E50403] hover:text-gray-300"
                        >
                          Apply Now
                        </h3>
                      </span>
                    </a>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex justify-center items-center h-[50vh]">
              <Loader />
            </div>
          )}
        </div>
      </section>

      {/* <!-- Modal --> */}
      <div
        class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="exampleModalCenter"
        tabindex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
          <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                class="text-xl font-medium leading-normal text-gray-800"
                id="exampleModalScrollableLabel"
              >
                {`Apply Now for ${pos?.name}`}
              </h5>
              <button
                type="button"
                class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body relative p-4">
              <form>
                <div class="mb-6">
                  <input
                    type="text"
                    required
                    placeholder="Your Name"
                    value={postApp.name}
                    onChange={(e) =>
                      setPostApp({ ...postApp, name: e.target.value })
                    }
                    class={FormStyle}
                  />
                </div>
                <div class="mb-6">
                  <input
                    type="email"
                    required
                    placeholder="Your Email"
                    value={postApp.email}
                    onChange={(e) =>
                      setPostApp({ ...postApp, email: e.target.value })
                    }
                    class={FormStyle}
                  />
                </div>
                <div class="mb-6">
                  <input
                    type="tel"
                    required
                    placeholder="Your Phone"
                    value={postApp.phone}
                    onChange={(e) =>
                      setPostApp({ ...postApp, phone: e.target.value })
                    }
                    class={FormStyle}
                  />
                </div>
                <div class="mb-6">
                  <select
                    disabled
                    value={postApp.job}
                    placeholder="Your Phone"
                    class={FormStyle}
                  >
                    <option value={pos?._id}>{pos?.name}</option>
                  </select>
                </div>

                {/* <div class="mb-6">
                  <h3 className="text-sm py-2">Upload Resume</h3>
                  <input class={FormStyle} type="file" name="" id="" />
                </div> */}
                <div>
                  <button
                    onClick={PostApplication}
                    type="submit"
                    class=" w-full
                        text-white
                        bg-[#E50403]
                        rounded
                        border border-primary
                        p-3
                        transition
                        hover:bg-opacity-90
                        "
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const JobDetail = [
  {
    location: "Gurgaon",
    position: "React JS Developer",
    jobType: "Full Time",
    exp: "1+ Yrs",
  },
  {
    location: "Gurgaon",
    position: "React JS Developer",
    jobType: "Full Time",
    exp: "1+ Yrs",
  },
  {
    location: "Gurgaon",
    position: "React JS Developer",
    jobType: "Full Time",
    exp: "1+ Yrs",
  },
  {
    location: "Gurgaon",
    position: "React JS Developer",
    jobType: "Full Time",
    exp: "1+ Yrs",
  },
  {
    location: "Gurgaon",
    position: "React JS Developer",
    jobType: "Full Time",
    exp: "1+ Yrs",
  },
  {
    location: "Gurgaon",
    position: "React JS Developer",
    jobType: "Full Time",
    exp: "1+ Yrs",
  },
];


export default Career;
