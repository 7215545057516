import { BsFacebook, BsTwitter, BsLinkedin, BsInstagram } from "react-icons/bs";

const Social_Links = [
  {
    icon: <BsFacebook />,
    path: "/",
  },
  {
    icon: <BsTwitter />,
    path: "/",
  },
  {
    icon: <BsLinkedin />,
    path: "/",
  },
  {
    icon: <BsInstagram />,
    path: "/",
  },
];

export default Social_Links;
