import React from "react";
import { AiTwotoneLike } from "react-icons/ai";
import { FaLaptopCode, FaSmile, FaHandshake } from "react-icons/fa";
import { MdWorkOutline } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import { AiOutlineTeam } from "react-icons/ai";
import { ImMobile } from "react-icons/im";
import CountUp from "react-countup";

const Stat = () => {
  return (
    <>
      {/* <section className="bg-[#232323] ">
        <div className="container px-4 md:px-4 grid grid-cols-2 md:grid-cols-4 gap-2 md:space-y-0 border-t py-16 border-[#ffffff21] md:space-x-5 justify-center mx-auto">
          {Stats.map((e) => {
            return (
              <div className="flex border-dashed border-2 py-8 border-white space-y-3 items-center flex-col">
                <span className="md:text-6xl text-4xl text-white">{e.icon}</span>

                <h2 className="md:text-5xl text-2xl font-bold text-gray-300">{e.nu}</h2>
                <h2 className="md:text-lg text-sm text-center  font-medium text-gray-300">{e.title}</h2>
              </div>
            );
          })}
        </div>
      </section> */}
      
        <section className=" bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 py-20">
          <div className={`container px-2 lg:px-12 mx-auto z-50`}>
            <div className="grid  gap-4 grid-cols-2 lg:grid-cols-4">
              {CountData.map((e, index) => {
                return (
                  <span
                    key={index}
                    className={`flex  py-6 lg:py-12 rounded-xl space-y-5 border-dashed border shadow-xl border-slate-500  flex-col items-center`}
                  >
                    <span className="flex space-x-5 text-white  items-center">
                      <span className="">
                        <img className="w-28" src={e.icon} alt="" />
                      </span>
                      <h2 className="text-3xl lg:text-4xl font-medium">
                        <CountUp end={e.num} duration={5} />+
                      </h2>
                    </span>
                    <h3 className="text-slate-300 text-base lg:text-xl tracking-wide font-medium">
                      {e.title}
                    </h3>
                  </span>
                );
              })}
            </div>
          </div>
        </section>
    </>
  );
};

// const Stats = [
//   {
//     icon: <FaLaptopCode />,
//     nu: "402+",
//     title: "PROJECT DEVELOPED",
//   },
//   {
//     icon: <AiTwotoneLike />,
//     nu: "97%",
//     title: "PROJECT DELIVERED",
//   },
//   {
//     icon: <FaSmile />,
//     nu: "402",
//     title: "HAPPY CLIENTS",
//   },
//   {
//     icon: <FaHandshake />,
//     nu: "99%",
//     title: "CLIENT SATISFACTION",
//   },
// ];
export const CountData = [
  {
    icon: "https://ik.imagekit.io/nboj7z3sl/WOrk_KKXdZU85E.png",
    num: "13",
    title: "Years in Business",
  },
  {
    icon: "https://ik.imagekit.io/nboj7z3sl/happy_kyWqTo0Gz.png",
    num: "300",
    title: "Happy Clients",
  },
  {
    icon: "https://ik.imagekit.io/nboj7z3sl/team_d1CnUPCwW.png",
    num: "40",
    title: "Technical Experts",
  },
  {
    icon: "https://ik.imagekit.io/nboj7z3sl/satisfication_MqDtbjle4b.png",
    num: "130",
    title: "Apps Delivered",
  },
];

export default Stat;
