import axios from 'axios';

const sendEmail = async (emailData) => {
    try {
        const response = await axios.post('https://send.api.mailtrap.io/api/send', emailData, {
            headers: {
                'Authorization': 'Bearer 42656466a3e9d2dc2ad194664f18241d',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error sending email:', error);
        throw error;
    }
};

export default sendEmail; 