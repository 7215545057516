import { Rings } from "react-loader-spinner";

export const Loader = () => {
  return (
    <Rings
      height="100px"
      width="100px"
      radius="9"
      color="green"
      ariaLabel="three-dots-loading"
      wrapperStyle
      wrapperClass
    />
  );
};
