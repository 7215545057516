function Cookies() {
    return (
        <>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">Cookie Policy</h1>

                <div className="space-y-6">
                    <section>
                        <h2 className="text-xl font-semibold mb-3">1. What Are Cookies</h2>
                        <p className="text-gray-700">
                            Cookies are small text files that are placed on your device when you visit our website. They help us provide you with a better experience by remembering your preferences and analyzing how you use our site.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">2. How We Use Cookies</h2>
                        <p className="text-gray-700">
                            We use cookies for the following purposes:
                        </p>
                        <ul className="list-disc ml-6 mt-2 text-gray-700">
                            <li>Essential cookies for website functionality</li>
                            <li>Analytics cookies to understand user behavior</li>
                            <li>Preference cookies to remember your settings</li>
                            <li>Marketing cookies for targeted advertising</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">3. Managing Cookies</h2>
                        <p className="text-gray-700">
                            You can control and manage cookies through your browser settings. Please note that disabling certain cookies may affect the functionality of our website.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">4. Third-Party Cookies</h2>
                        <p className="text-gray-700">
                            Some cookies are placed by third-party services that appear on our pages. We do not control these cookies and recommend reviewing the privacy policies of these third parties.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">5. Contact Us</h2>
                        <p className="text-gray-700">
                            If you have any questions about our cookie policy, please contact us at helpdesk@usdservices.in.
                        </p>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Cookies;