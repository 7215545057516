function Privacy() {
    return (
        <>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

                <div className="space-y-6">
                    <section>
                        <h2 className="text-xl font-semibold mb-3">1. Information We Collect</h2>
                        <p className="text-gray-700">
                            We collect information that you provide directly to us, including name, email address, phone number and other contact details when you use our services or communicate with us.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">2. How We Use Your Information</h2>
                        <p className="text-gray-700">
                            We use the information we collect to:
                        </p>
                        <ul className="list-disc ml-6 mt-2 text-gray-700">
                            <li>Provide and maintain our services</li>
                            <li>Communicate with you about our services</li>
                            <li>Improve and optimize our services</li>
                            <li>Protect against fraud and unauthorized access</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">3. Information Sharing</h2>
                        <p className="text-gray-700">
                            We do not sell or rent your personal information to third parties. We may share your information with service providers who assist in our operations.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">4. Data Security</h2>
                        <p className="text-gray-700">
                            We implement appropriate security measures to protect your personal information from unauthorized access, alteration, or destruction.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mb-3">5. Your Rights</h2>
                        <p className="text-gray-700">
                            You have the right to access, correct, or delete your personal information. Contact us at helpdesk@usdservices.in for any privacy-related concerns.
                        </p>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Privacy;