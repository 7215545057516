import React, { useState } from "react";
import sendEmail from "../../utils/mailtrapApi";
import Address from "./Address";
import Form, { FormStyle } from "./Form";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [contactData, setContactData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "",
  });

  const handleSendEmail = async () => {
    const emailData = {
      from: { email: "hello@usdservices.in", name: "Mailtrap Test" },
      to: [{ email: "jnikhil652@gmail.com" }],
      subject: "You are awesome!",
      text: `${JSON.stringify(contactData, null, 2)}`,
      category: "Integration Test",
    };

    toast.success("Email sent successfully");
    try {
      const result = await sendEmail(emailData);
      console.log("Email sent successfully:", result);
    } catch (error) {
      console.error("Failed to send email:", error);

    }
  };

  return (
    <>
      <section id="contact" class=" mx-auto container md:px-6 p-3 py-14  overflow-hidden relative z-10">
        <div class="flex  flex-wrap container lg:justify-between">
          <div class="w-full container lg:w-1/2 xl:w-6/12 ">
            <div class="max-w-[570px] mb-12 lg:mb-0">
              <h2 class="text-dark mb-6 uppercase font-bold text-[32px] sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                GET IN TOUCH WITH US
              </h2>
              <p class="text-base text-body-color leading-relaxed mb-9">
                We're here to help! Whether you have questions about our services, need technical support, or want to explore business opportunities, our team is ready to assist you. Feel free to reach out through any of the contact methods below.
              </p>
              {Address.map((e) => {
                return (
                  <div class="flex mb-8 max-w-[370px] w-full">
                    <div
                      class="
                     max-w-[60px]
                     sm:max-w-[70px]
                     w-full
                     h-[60px]
                     sm:h-[70px]
                     flex
                     items-center
                     justify-center
                     mr-6
                     overflow-hidden
                     bg-primary bg-opacity-5
                     text-primary
                     rounded
                     "
                    >
                      <span className="text-2xl bg-red-500 text-white p-4 rounded-md">
                        {" "}
                        {e.icon}
                      </span>
                    </div>
                    <div class="w-full">
                      <h4 class="font-bold text-dark text-xl mb-1">
                        {e.title}
                      </h4>
                      {e.type === "email" ? <a href={`mailto:${e.address}`}>{e.address}</a> : e.type === "phone" ? <a href={`tel:${e.address}`}>{e.address}</a> : <p class="text-base text-body-color">{e.address}</p>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div class="w-full lg:w-1/2 xl:w-5/12 ">
            <div class="bg-white border relative rounded-lg p-8 sm:p-12 shadow-lg">
              {/* <form> */}
              <div class="mb-6">
                <input
                  type="text"
                  placeholder="Your Name"
                  value={contactData.name}
                  onChange={(e) => {
                    setContactData({ ...contactData, name: e.target.value });
                  }}
                  class={FormStyle}
                />
              </div>
              <div class="mb-6">
                <input
                  type="tel"
                  placeholder="Your Number"
                  value={contactData.phone}
                  onChange={(e) => {
                    setContactData({ ...contactData, phone: e.target.value });
                  }}
                  class={FormStyle}
                />
              </div>
              <div class="mb-6">
                <input
                  type="email"
                  placeholder="Your Email"
                  value={contactData.email}
                  onChange={(e) => {
                    setContactData({ ...contactData, email: e.target.value });
                  }}
                  class={FormStyle}
                />
              </div>

              <div class="mb-6">
                {/* <textarea
                      rows="6"
                      placeholder="Your Message"
                      class={FormStyle}
                    ></textarea> */}
                <select
                  onChange={(e) => {
                    setContactData({
                      ...contactData,
                      service: e.target.value,
                    });
                  }}
                  value={contactData.service}
                  class={FormStyle}
                  name=""
                  id=""
                >
                  <option value="">--Select Service--</option>
                  <option value="Web Development">Web Development</option>
                  <option value="App Development">App Development</option>
                  <option value="SEO">SEO</option>
                  <option value="SMM">SMM</option>
                </select>
              </div>
              <div>
                <button
                  onClick={handleSendEmail}
                  class=" w-full
                        text-white
                        bg-[#E50403]
                        rounded
                        border border-primary
                        p-3
                        transition
                        hover:bg-opacity-90
                        "
                >
                  Send Message
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
